<template>
<div :class="column + ' product-outer'">
  <div :class="'product-card ' + (border ? 'border' : '')" v-if="item.itemNumber">
    <!--<div class="promo">PROMO</div>-->
    <div class="marks">
      <img src="@/assets/marks/icona1.png" v-if="item.tags.includes('ORGANIC')" v-tooltip="'BIO'" />
      <img src="@/assets/marks/icona2.png" v-if="item.tags.includes('VEGAN')" v-tooltip="'VEGAN'" />
      <img src="@/assets/marks/icona3.png" v-if="item.tags.includes('GLUTEN_FREE')" v-tooltip="'GLUTEN FREE'" />
    </div>

    <router-link :to="'/product/' + item.itemNumber + '/' + $utils.getSlug(item.productName)">
      <div v-if="!$utils.isMouseover(item.itemNumber) || (item.images && item.images.length < 2)">
        <img :src="'https://shop.altromercato.it/products/' + item.images[0] + '?v=2'" class="product-image" v-if="item.images && item.images[0]" />
        <img src="https://shop.altromercato.it/products/placeholder.png" class="product-image" v-if="!item.images" />
      </div>

      <div v-if="$utils.isMouseover(item.itemNumber) && item.images && item.images.length > 1" class="hover-img">
        <img :src="'https://shop.altromercato.it/products/' + item.images[0] + '?v=2'" class="product-image front" />
        <img :src="'https://shop.altromercato.it/products/' + item.images[1] + '?v=2'" class="product-image back" />
      </div>
    </router-link>

    <div class="product-content">

      <router-link :to="'/product/' + item.itemNumber + '/' + $utils.getSlug(item.productName)">
        <h2 v-if="!$utils.isGift(item.itemNumber)">{{$utils.capitalize(item.productName)}}</h2>
        <h2 v-if="$utils.isGift(item.itemNumber)">{{$utils.capitalize(item.productName.replace(/\s+\d+\s*€?$/, ''))}}</h2>
      </router-link>

      <div class="row" v-if="item.stock < 1 || !item.acquistabile && !isMasterNoDisponibile">
        <p class="error" v-if="!isMasterNoDisponibile">Prodotto non disponibile</p>
      </div>

      <div class="discount" v-if="item.sconto > 0 && ((item.stock > 0 && item.acquistabile) || isMasterNoDisponibile)">
        <span class="old-price">&euro; {{item.productPrice.toFixed(2)}}</span>
        <span class="minus">-{{item.sconto}}%</span>
      </div>
      <div class="discount" v-else style="visibility: hidden;">
        <span class="old-price">&nbsp;</span>
        <span class="minus">&nbsp;</span>
      </div>

      <div class="row" v-if="item.stock > 0 && item.acquistabile || isMasterNoDisponibile">
        <div class="price col-6">
          <span class="current">&euro; {{$utils.getProductPrice(item).toFixed(2)}}</span>
          <span class="online-price">Prezzo online</span>
        </div>
        <div class="col-6">
          <div class="add-to-cart" @click="addToCart(item)" v-if="!$utils.isGift(item.itemNumber)">
            aggiungi
            <img src="@/assets/cart-white.png" class="cart-icon" />
            <!--<span class="fas fa-shopping-cart"></span>-->
          </div>
        </div>
      </div>

      <!--<div style="height: 32px" v-if="item.sconto == 0 || item.stock < 1 || !item.acquistabile"></div>-->
    </div>
  </div>
</div>
</template>

<script>
import utils from '@/utils.js'

export default {
  name: 'ProductThumb',
  props: ['column', 'border', 'productId'],

  data: function () {
    return {
      item: {},
      isMasterNoDisponibile: false
    }
  },

  mounted () {
    let disponibile = false
    this.item = utils.getProduct(this.productId)

    if (this.item.masterCode && this.item.itemNumber == this.item.masterCode && this.item.stock == 0) {

      window.shop_products.forEach((item) => {
        if (item.masterCode == this.item.itemNumber && item.stock > 0) {
          disponibile = true
        }
      })
    }

    if (disponibile) {
      this.isMasterNoDisponibile = true
    }
  },

  methods: {
    addToCart(item) {
      this.$toast.open('Articolo aggiunto al carrello')
      this.$store.commit('addToCart', {
        item,
        qty: item.multiplo ? item.multiplo : 1
      })
    }
  }
}
</script>

<style scoped>
.product-outer {
  background-color: #fff;
  border-color: transparent;
  border-style: solid;
  border-width: 0 5px 25px 5px;
  background-clip: padding-box;
}

.product-card {
  width: 100%;
  min-height: 442px;
  padding: 20px 0;
  background-color: #fff;
  position: relative;
}

.product-card .promo {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 0 6px;
  height: 22px;
  line-height: 24px;
  color: #fff;
  background-color: #f09049;
  font-weight: 800;
  font-size: 14px;
}

.product-image {
  width: 235px;
  height: 235px;
  object-fit: cover;
  display: block;
  margin: 0 auto 48px auto;
}

.marks {
  position: absolute;
  top: 16px;
  left: 10px;
  width: 32px;
}

.marks img {
  width: 32px;
  height: auto;
  margin-bottom: 3px;
}

.product-content {
  padding: 0 22px;
}

.product-content h2 {
  font-size: 17px;
  line-height: 130%;
  font-weight: 700;
  font-family: neue-haas-grotesk-display, sans-serif;
  margin: 10px 0 0 0;
  min-height: 66px;
}

.product-card .discount {
  position: relative;
  left: -10px;
  padding: 0 6px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  background-color: #f09049;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  font-family: neue-haas-grotesk-display, sans-serif;
  margin: 5px 0;
}

.product-card .discount .old-price {
  text-decoration: line-through;
  display: inline-block;
  margin-right: 6px;
}

.product-card .price .current {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  line-height: 100%;
}

.product-card .price .online-price {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.4px;
}

.add-to-cart {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  color: #fff;
  background-color: #000;
  font-size: 14px;
  font-weight: 700;
  font-family: neue-haas-grotesk-display, sans-serif;
  letter-spacing: 0.2px;
  text-align: center;
  max-width: 120px;
  float: right;
  cursor: pointer;
}

.add-to-cart span {
  margin-left: 7px;
  width: 27px;
}

.border {
  border: 1px solid #eee !important;
}

.hover-img .back {
  display: none;
}

.hover-img:hover .back {
  display: block;
}

.hover-img:hover .front {
  display: none;
}

@media (max-width: 680px) {
  .product-outer {
    border-width: 0 0 25px 0;
  }
}
</style>
