<template>
<div class="category-view" :style="{minHeight: loaded ? 'auto' : '900000px'}">

  <div :class="'menu-filter-shadow ' + (menuOpen ? 'open' : '')" @click="closeMenu()"></div>
  <div :class="'menu-filter only-mobi ' + (menuOpen ? 'open' : '')">
    <div class="overflower">
      <img src="@/assets/logo.png" class="logo" />

      <br><br><br>

      <div v-if="secondLevel.length">
        <div class="single-filter" v-for="(item, k) in secondLevel" v-bind:key="k">
          <router-link :to="'/category/' + item.categoryCode + '/' + $utils.getSlug(item.categoryName)" class="filter-name neue">
            {{item.categoryName ? item.categoryName.substr(item.categoryName.indexOf(" ") + 1) : ''}}
          </router-link>
          <div class="filter-content" v-if="thirdLevel[item.categoryCode].length">
            <router-link :to="'/category/' + item2.categoryCode + '/' + $utils.getSlug(item2.categoryName)" class="filter-value neue" v-for="(item2, k2) in thirdLevel[item.categoryCode]" v-bind:key="k2">
              {{item.categoryName ? item2.categoryName.substr(item2.categoryName.indexOf(" ") + 1) : ''}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="full-width flex-row-mobile">
    <div class="num-prodotti neue">{{items.length}} prodotti</div>

    <div v-if="secondLevel.length">
      <span class="only-mobi fas fa-filter" @click="openMenu"></span>
    </div>

    <div class="ordina neue">
      Ordina per <span class="fas fa-caret-down"></span>
      <select v-model="productsOrder" class="opaque" @change="changeOrder">
        <option value="">Ordinamento predefinito</option>
        <option value="19">Prezzo crescente</option>
        <option value="91">Prezzo decrescente</option>
        <option value="az">Nome crescente</option>
        <option value="za">Nome descrescente</option>
      </select>
    </div>

  </div>


  <div class="full-width">
    <div class="product-container" v-bind:key="paintIndex">
      <ProductThumb
        v-show="k < currentIndex"
        :column="'col-' + (12 / cols)"
        v-for="(item, k) in items"
        v-bind:key="k"
        :productId="item.itemNumber" />

      <div class="flex-center">
        <div class="button" @click="moreIndex" v-if="currentIndex < items.length">Mostra altri prodotti</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ProductThumb from '@/components/ProductThumb'
import utils from '@/utils.js'
import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'CategoryView',

  components: {
    ProductThumb
  },

  data: function () {
    return {
      loaded: false,
      items: [],
      fetchedItems: [],
      menuOpen: false,
      cat: {},
      secondLevel: [],
      thirdLevel: {},
      currentIndex: this.$viewCurrentIndex[this.$route.params.code || this.$route.params.name] || this.numItems,
      productsOrder: '',
      paintIndex: 1,
      list: '',
    }
  },

  watch: {
    '$route.params.code': function () {
      Vue.$forceUpdate();
      this.setItems()
      this.currentIndex = this.$viewCurrentIndex[this.$route.params.code || this.$route.params.name] || this.numItems
    }
  },

  props: {
    slug: String,
    code: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    },
    cols: {
      type: Number,
      default: 2
    },
    isBrand: {
      type: Boolean,
      default: false
    },
    numItems: {
      type: Number,
      default: 10
    },
    soloPromozione: {
      type: Boolean,
      default: false
    },
    escludiEsauriti: {
      type: Boolean,
      default: false
    },
    prodottiFiltrati: {
      type: Array,
      default: () => []
    },
    filtriTag: {
      type: Array,
      default: () => []
    }
  },

  mounted () {
    this.setItems()
    this.initToParam()
  },

  methods: {

    async setItems () {
      if(this.$route.name === "Search") {
        this.list = "Ricerca"
      } else if(this.$route.name === "Category") {
        this.list = utils.getCategory(this.$route.params.code)?.categoryName
      }

      let objSize = function(obj) {
        return Object.keys(obj).length
      }

      let categoryOrder = await axios.get('https://shop.altromercato.it/cms/simplestore/getOrder.php?category=' + this.$route.params.code)

      let tmpItems = utils.getAllProducts(this.$route.params.code || this.$route.params.name, this.isBrand, this.filter, this.filtriTag, this.escludiEsauriti, this.soloPromozione, this.prodottiFiltrati)

      if (objSize(categoryOrder.data)) {
        let modaCodes = []

        for (let index in categoryOrder.data) {
          let order = parseInt(categoryOrder.data[index])
          if(!Number.isFinite(order)) {
            order = Number.MAX_SAFE_INTEGER
          }
          modaCodes.push({
            code: index,
            order: order
          })
        }

        modaCodes.sort(function(a, b) {
          return parseInt(a.order) - parseFloat(b.order);
        });

        let tmp = []
        modaCodes.forEach((modaC) => {
          for (let index = 0; index < tmpItems.length; index++) {
            if (tmpItems[index].itemNumber == modaC.code) {
              tmp.push(tmpItems[index])
              tmpItems.splice(index, 1)
              break
            }
          }
        })

        tmpItems = tmp.concat(tmpItems)
      }

      this.fetchedItems = tmpItems
      this.items = tmpItems
      this.loaded = true

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'listing',
        ecommerce: {
          currencyCode: 'EUR',
          impressions: this.items.slice(0, this.currentIndex).map((item, index) => ({
            name: item.productName,
            id: item.itemNumber,
            price: item.productPrice,
            item_name: item.productName,
            item_id: item.itemNumber,
            list: this.list,
            postition: index + 1
          }))
        }
      })
    },

    openMenu(evt) {
      evt.preventDefault()
      this.menuOpen = true
    },

    closeMenu () {
      this.menuOpen = false
    },

    moreIndex () {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'listing',
        ecommerce: {
          currencyCode: 'EUR',
          impressions: this.items.slice(this.currentIndex, this.currentIndex + this.numItems).map((item, index) => ({
            name: item.productName,
            id: item.itemNumber,
            price: item.productPrice,
            item_name: item.productName,
            item_id: item.itemNumber,
            list: this.list,
            postition: this.currentIndex + index + 1
          }))
        }
      })
      this.currentIndex = this.currentIndex + this.numItems
      this.$viewCurrentIndex[this.$route.params.code || this.$route.params.name] = this.currentIndex
    },

    initToParam () {

      if (this.$route.params.code) {
        this.cat = utils.getCategory(this.$route.params.code)
        this.secondLevel = utils.getSecondLevel(this.$route.params.code)
        this.path = [{
          label: 'Home',
          path: '/'
        }].concat(utils.getCategoryPath(this.$route.params.code).reverse())

        this.secondLevel.forEach((item) => {
          this.thirdLevel[item.categoryCode] = utils.getSecondLevel(item.categoryCode)
        })
      }
    },

    changeOrder () {
      switch (this.productsOrder) {
        case '':
          this.items = this.fetchedItems
          break
        case 'az':
          this.items = this.fetchedItems.sort((a, b) => {
            if (a.productName.toLowerCase() > b.productName.toLowerCase()) return 1
            if (a.productName.toLowerCase() < b.productName.toLowerCase()) return -1
            return 0
          })
          break
        case 'za':
          this.items = this.fetchedItems.sort((a, b) => {
            if (a.productName.toLowerCase() > b.productName.toLowerCase()) return -1
            if (a.productName.toLowerCase() < b.productName.toLowerCase()) return 1
            return 0
          })
          break
        case '19':
          this.items = this.fetchedItems.sort((a, b) => {
            let priceA = this.$utils.getProductPrice(a)
            let priceB = this.$utils.getProductPrice(b)
            if (priceA > priceB) return 1
            if (priceA < priceB) return -1
            return 0
          })
          break
        case '91':
          this.items = this.fetchedItems.sort((a, b) => {
            let priceA = this.$utils.getProductPrice(a)
            let priceB = this.$utils.getProductPrice(b)
            if (priceA < priceB) return 1
            if (priceA > priceB) return -1
            return 0
          })
          break
      }

      this.paintIndex++
    }
  }
}
</script>

<style scoped>
  .category-view .full-width {
    justify-content: space-between;
  }
  .ordina, .num-prodotti {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 26px;
  }

  .ordina {
    font-weight: 500;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .ordina select {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ordina span {
    margin-left: 4px;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 680px) {
    .full-width.flex-row-mobile {
      padding: 0 10px;
      flex-direction: row;
    }

    .product-container {
      padding: 10px;
    }
  }
</style>
